/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { apiPrefix } from "../lib/helper";
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { useNavContext } from "../providers/navProvider";
import { useProductsContext } from "../providers/productsProvider";
import { makeApiCall } from "../services/apiService";
import "../styles/nav.scss";
import { IShare } from "../types/iShare";
import { NavSections } from "./navsection";

export const TopNav: React.FunctionComponent = () => {
  const [shares, setShares] = useState<IShare[]>([]);
  const authContext = useAuthenticationContext();
  const navContext = useNavContext();
  const productContext = useProductsContext();
  const loadContext = useLoadContext();

  const loadProducts = useCallback(() => {
    if (authContext.Result?.IsAuthenticated) {
      loadContext.SetLoadingMessage({ message: `Just a moment...`, hideBody: true });
      Promise.all([
        makeApiCall(`${apiPrefix}getProducts`),
        makeApiCall(`${apiPrefix}getFileShares`)]).then(([products, shares]) => {
          productContext.SetProducts(products);
          setShares(shares.map((s: IShare) => {
            s.ProductCategory = "PARTNERS"
            return s;
          }));
        }).finally(() => {
          loadContext.SetLoadingMessage(undefined);
        })
    }
  }, [authContext.Result])

  useEffect(() => {
    if (authContext.Result?.IsAuthenticated && window.location.pathname.toLowerCase() !== "/consent") {
      loadProducts()
    }
  }, [authContext.Result])

  return (
    <>
      <a href={undefined} className="hamburgerIcon" onClick={() => { navContext.SetResponsive(navContext.Responsive === 'responsive' ? '' : 'responsive') }} title={navContext.Responsive === 'responsive' ? 'Collapse Menu' : 'Expand Menu'}><Icon iconName={navContext.Responsive === 'responsive' ? 'ChevronUnfold10' : 'CollapseMenu'} className="topNavHamburger" /></a>
      <div className={`topnav ${navContext.Responsive}`} id="cppTopnav">
        <NavLink to="/" className={navContext.ActiveNode === 'HOME' ? 'activeTopLink' : 'topLink'} onClick={() => {
          navContext.SetActiveNode('HOME');
          navContext.SetResponsive("");
        }}>HOME</NavLink>
        {authContext.Result?.IsAuthenticated && <NavSections products={productContext?.Products?.length > 0 ? productContext.Products : []} titleFieldName="Title" urlPrefix="product" addSupportLinks={true} />}
        {shares.length > 0 && <NavSections products={shares} titleFieldName="PartnerName" urlPrefix="share" />}
      </div>
    </>
  )
}